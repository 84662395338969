import Vue from 'vue'
import Router from 'vue-router'

import home from '../view/home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: '/product/',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '*',
      redirect: '/index'
    },
    {
      path: '/',
      component: home,
      redirect: '/index',
      children: [
        {
          path: '/index',
          component: (resolve) => require(['../view/index.vue'], resolve),
          name: '首页',
          meta: {
            title: '首页'
          }
        },
        {
          path: '/center',
          component: (resolve) => require(['../view/ProductCenter.vue'], resolve),
          name: '产品中心',
          meta: {
            title: '产品中心'
          }
        },
        {
          path: '/solution',
          component: (resolve) => require(['../view/Solution.vue'], resolve),
          name: '解决方案',
          meta: {
            title: '解决方案'
          }
        },
        {
          path: '/case',
          component: (resolve) => require(['../view/case.vue'], resolve),
          name: '案例展示',
          meta: {
            title: '案例展示'
          }
        },
        {
          path: '/joinus',
          component: (resolve) => require(['../view/Joinus.vue'], resolve),
          name: '加入我们',
          meta: {
            title: '加入我们'
          }
        }
      ]
    }
  ]
})
