<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
    }
  }
}
</script>

<style>
body {
  margin: 0;
}
p {
  margin: 0;
}
img{
  display: block;
}
.box{
  max-width: 1300px;
  margin: 0 auto;
}
ul,li,ol{list-style:none;margin: 0;padding: 0;}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  width: 100%;
}
</style>
