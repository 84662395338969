<template>
    <div class="box">
        <div class="foot">
            <div class="footLeft">
                <ul>
                    <li>
                        <div>产品服务</div>
                        <dl>
                            <dd>方案咨询</dd>
                            <dd>代理咨询</dd>
                            <dd>会议管理</dd>
                            <dd>公告管理</dd>
                        </dl>
                    </li>
                    <li>
                        <div>解决方案</div>
                        <dl>
                            <dd>智慧教育</dd>
                            <dd>成绩分析系统</dd>
                            <dd>教育大数据云平台</dd>
                            <dd>题库系统</dd>
                            <dd>财税云系统</dd>
                        </dl>
                    </li>
                    <li>
                        <div>关于我们</div>
                        <dl>
                            <dd>公司简介</dd>
                            <dd>教育云</dd>
                        </dl>
                    </li>
                    <li>
                        <div>产品中心</div>
                        <dl>
                            <dd>联系信息</dd>
                            <dd>智慧校园</dd>
                            <dd>实验考试</dd>
                        </dl>
                    </li>
                </ul>
            </div>
            <div class="footRight">
                <p>关注我们</p>
                <img src="../../../assets/masterStation/code.png" alt="柒彩科技">
                <span>打开微信扫一扫</span>
            </div>
        </div>
        <div class="FilingNo">
            <div>
                <p>贵州省普通高等学校教育大数据技术及应用工程技术研究中心</p>
                <p>贵州忠义柒彩科技开发有限公司</p>
            </div>
            <span> 联合研发 </span>
            <br /> <a href="http://beian.miit.gov.cn/" target="_blank">黔ICP15000145号-6</a>
        </div>
    </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {}
  },
  methods: {},
  created () { }
}
</script>
<style lang="less" scoped>
.foot {
    display: flex;
    .footLeft {
        width: 70%;
    }
    .footRight {
        width: 30%;
        text-align: center;
        position: relative;
        font-size: 16px;
        &::after {
            content: '';
            display: flex;
            position: absolute;
            left: 0;
            top: 15%;
            height: 70%;
            width: 3px;
            background-color: #6b6b6b;
            border-radius: 5px;
        }
        img {
            width: 110px;
            background-color: #fff;
            margin: 15px auto 10px;
            display: block;
        }
        span {
            display: block;
            text-align: center;
            font-size: 12px;
            color: #c3c3c3;
        }
    }
    ul {
        display: flex;
        li {
            width: 22%;
            div {
                margin-bottom: 20px;
                font-size: 18px;
                color: #fff;
            }
            dd {
                font-size: 14px;
                margin: 0;
                margin-bottom: 8px;
                color: #c3c3c3;
            }
        }
    }
}
.FilingNo {
  margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 60px;
    align-items: center;
    justify-content: center;
    p {
        color: #fff;
        font-size: 13px;
        margin-left: 10px;
        text-align: right;
    }
    span {
        margin-left: 20px;
        border-left: 1px solid #e6e6e6;
        padding-left: 20px;
        color: #fff;
    }
    a {
        display: block;
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 12px;
    }
}
</style>
