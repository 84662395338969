<template>
  <div>
    <el-scrollbar>
      <el-container>
        <el-header>
          <headertop></headertop>
        </el-header>
        <el-main>
          <keep-alive>
            <router-view></router-view>
          </keep-alive>
        </el-main>
        <el-footer>
          <footerbtm></footerbtm>
        </el-footer>
      </el-container>
    </el-scrollbar>
  </div>
</template>

<script>

import headertop from '../component/head'
import footerbtm from '../component/footer'

export default {
  name: 'home',
  components: { headertop, footerbtm },
  data () {
    return {}
  },
  methods: {},
  created () {}
}
</script>
<style lang="less" scoped>
.el-scrollbar {
    height: 100vh;
    width: 100%;
    /deep/ .el-scrollbar__wrap {
      overflow: scroll;
      height: 100% !important;
      overflow-x: hidden !important;
    }
}
.el-header {
  height: 70px !important;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 5px rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
  width: 100%;
  z-index:99999;
}
.el-main {
 padding: 0;
}
.el-footer{
  height: auto !important;
  background-color: #1A1A1A;
  padding: 30px 0;
  color: #fefefe;
}

</style>
