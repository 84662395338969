<template>
  <div class="box">
    <div class="top_left">
      <img src="../../../assets/masterStation/logo_txt.png" alt="柒彩科技">
    </div>
    <div class="top_cont">
      <ul>
        <template v-for="(item,index) in navlist">
          <li :key="index" :class="$route.path==item.path?'is_act':''" @click="toPath(item.path)">{{item.name}}</li>
        </template>
        <!-- <li :class="$route.path=='/index'?'is_act':''">首页</li>
        <li :class="$route.path=='/ProductCenter'?'is_act':''">产品中心</li>
        <li :class="$route.path=='/Solution'?'is_act':''">解决方案</li>
        <li :class="$route.path=='/index3'?'is_act':''">案例展示</li>
        <li :class="$route.path=='/index4'?'is_act':''">加入我们</li> -->
      </ul>
    </div>
    <div class="top_right">
      <div>
        <i class="iconfont icon-kefu"></i>
        <span>085185611235 </span>
      </div>
      <p>在线咨询</p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      navlist: [
        { name: '首页', path: '/index' },
        { name: '产品中心', path: '/center' },
        { name: '解决方案', path: '/solution' },
        { name: '案例展示', path: '/case' },
        { name: '加入我们', path: '/joinus' }
      ]
    }
  },
  created () {},
  methods: {
    toPath (path) {
      if (path !== '/' && path !== this.$route.path) {
        this.$router.push({ path: path })
      }
    }
  }
}
</script>
<style lang="less" scoped>

.box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  height: 100%;
  .top_left {
    img {
      height: 40px;
    }
  }
  .top_cont {
    height: 100%;
    ul {
      display: flex;
      height: 100%;
      li {
        height: 100%;
        margin: 0 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
      }
      .is_act {
        font-weight: 600;
        &:after {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 4px;
          background-color: #fff;
          border-radius: 3px;
        }
      }
    }
  }
  .top_right {
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      display: flex;
      align-items: center;
      margin-right: 30px;
      i {
        margin-right: 5px;
        font-size: 24px;
      }
    }
    p {
      font-size: 14px;
      height: 30px;
      line-height: 30px;
      padding: 0 20px 0 10px;
      border-radius: 18px;
      border: 2px solid #fff;
      cursor: pointer;
    }
  }
}
</style>
